import React, { useState } from "react";
import cx from "classname";
import * as styles from "./styles.module.scss";
import RichText from "../../components/RichText";
import openIcon from "./open.svg";
import closeIcon from "./close.svg";

export function Accordion({ data }) {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div
      className={cx(styles.questionContainer, {
        [styles.expanded]: isExpanded
      })}
    >
      <button
        className={styles.question}
        onClick={() => setExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-haspopup={true}
      >
        <div className={styles.questionWrapper}>
          <div className={styles.questionWidth}>{data.question}</div>
          <div className={styles.iconContainer} aria-hidden="true">
            <img src={openIcon} className={styles.iconOpen} alt="" />
            <img src={closeIcon} className={styles.iconClose} alt="" />
          </div>
        </div>
      </button>

      <div className={styles.answer}>
        <RichText value={data.answer} />
      </div>
    </div>
  );
}
