import React from "react";
import BasePage from "../components/BasePage";
import SiteGrid from "../components/SiteGrid";
import SiteGutter from "../components/SiteGutter";
import Headline from "../components/Headline";
import {
  container,
  accentOrb,
  headline,
  centeredAccordion
} from "./styles.module.scss";
import accentOrbImg from "../Reviews/Accent Orb.svg";
import FeaturedFAQs from "./FeaturedFAQs";
import { Accordion } from "./Accordion";

export default function FAQ({ pageContext }) {
  return (
    <BasePage pageContext={pageContext}>
      <div className={container}>
        <img src={accentOrbImg} loading="lazy" alt="" className={accentOrb} />
        <SiteGutter>
          <SiteGrid>
            <h1 className="srOnly">Frequently Asked Questions</h1>
            <Headline type="s32" className={headline}>
              {pageContext.slices.headline}
            </Headline>
            <FeaturedFAQs data={pageContext.slices.FeaturedFAQS} />
            <ul className={centeredAccordion}>
              {Object.values(pageContext.slices.OtherQuestions).map(
                (question, index) => {
                  return (
                    <li key={index}>
                      <Accordion data={question} />
                    </li>
                  );
                }
              )}
            </ul>
          </SiteGrid>
        </SiteGutter>
      </div>
    </BasePage>
  );
}
