// extracted by mini-css-extract-plugin
export var anchor = "styles-module--anchor--QVae-";
export var answer = "styles-module--answer---vWYS";
export var columns = "styles-module--columns--lgwY-";
export var container = "styles-module--container--zmwhk";
export var expanded = "styles-module--expanded--ZmzPc";
export var fadeIn = "styles-module--fadeIn--bWNux";
export var fadeInAndMoveUp = "styles-module--fadeInAndMoveUp--iZoa9";
export var headline = "styles-module--headline--Q7sME";
export var iconClose = "styles-module--iconClose--6Eowk";
export var iconContainer = "styles-module--iconContainer--kLaDT";
export var iconOpen = "styles-module--iconOpen--Tf+q7";
export var image = "styles-module--image--o9Izj";
export var left = "styles-module--left--FbL6w";
export var question = "styles-module--question--tltn4";
export var questionContainer = "styles-module--questionContainer--sFFE2";
export var questionWidth = "styles-module--questionWidth--6ukyG";
export var questionWrapper = "styles-module--questionWrapper--qjHz3";
export var right = "styles-module--right--2GNnB";
export var scaleUp = "styles-module--scaleUp--ZxMuq";
export var slideIn = "styles-module--slideIn--XuTHc";