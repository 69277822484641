import React from "react";
import {
  container,
  cardContainer,
  faqBody,
  cardTitle,
  titleMargin
} from "./styles.module.scss";
import Body from "../../components/Body";
import cx from "classname";

export default function FeaturedFAQs({ data }) {
  return (
    <div className={container}>
      {Object.values(data).map((faq, i) => (
        <FAQCard key={i} title={faq.question} body={faq.answer} />
      ))}
    </div>
  );
}

export function FAQCard({ title, body, className }) {
  return (
    <div className={cx(cardContainer, className)}>
      <span className={cardTitle}>{title}</span>
      <div className={titleMargin}></div>
      <Body richText={body} className={faqBody} />
    </div>
  );
}
