import React from "react";
import * as styles from "./styles.module.scss";
import cx from "classname";
import RichText from "../RichText";

export default function Body({ children, richText, tagType, size, className }) {
  return React.createElement(tagType, {
    children:
      richText && richText.raw ? <RichText value={richText} /> : children,
    className: cx(className, styles[size])
  });
}

Body.defaultProps = {
  tagType: "div",
  size: "normal"
};
